import { Component, OnInit } from '@angular/core';
import { Sponsor } from 'src/app/sponsors/models/sponsor';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss'],
})
export class SponsorsComponent implements OnInit {
  sponsors: Sponsor[] = [
    { image: 'dedrielinden.jpg' },
    { image: 'denotter.jpg', url: 'https://www.lmbdenotter.nl/' },
    { image: 'jvb.jpg', url: 'https://www.boxtelbv.nl/' },
    { image: 'kdl.jpg', url: 'https://www.kdl-bouwontwerp.nl/' },
    { image: 'stoof.jpg', url: 'http://stoofbouwkostenadvies.nl/' },
    { image: 'vanheel.jpg', url: 'https://www.vanheelmilieuconsult.nl/' },
    { image: 'vdl.jpg' },
    { image: 'weselconsult.jpg', url: 'https://weselconsult.nl/' },
    { image: 'wimsteenbekkers.jpg', url: 'https://www.fysiosteenbekkers.nl/' },
    { image: 'kollenburg.jpg', url: 'https://autorijschoolgertinekollenburg.nl/' }
  ];

  constructor() {}

  ngOnInit(): void {}
}
