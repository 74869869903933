<section class="px-3 px-lg-5 pb-2 pb-lg-5 pt-2 pt-lg-3 bg-dark white-text">
  <div class="text-center">
    <h2 class="h1-responsive font-weight-bold text-center elegant-color-dark my-4">Over Fanfare Alem</h2>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 text-justify align-middle">
        <p class="h1-responsive">Geschiedenis</p>
        <p class="about-paragraph">
          Fanfare Alem is opgericht 10 oktober 1965 en is reeds {{ageInYears}} jaren jong.
          Vanaf begin 1965 kregen Alemse kinderen, op verzoek van Harmonie Semper Crescendo te Kerkdriel, muziekles via
          muziekschool “Peter van Anrooij” te Zaltbommel. Deze lessen werden in Alem gegeven. Vanaf september 1965
          moesten de kinderen ook naar de repetitie in Kerkdriel en dat ging niet zonder slag of stoot. Grote en kleine
          problemen volgden.
        </p>
        <p class="about-paragraph">
          Kinderen van 10 tot 12 jaar moesten in de herfst en de winter op de fiets naar Kerkdriel over een onverlichte
          weg. Bovendien waren de kinderen veel te laat thuis, de repetitie duurde immers tot 22.00 uur. Uit deze
          vervelende situatie ontstond een nieuwe vereniging: “Jeugdfanfare Alem”.
        </p>
      </div>
      <div class="col-md-8 align-middle justify-content-center">
        <div class="view overlay">
          <figure>
            <img class="img-fluid" src="../../assets/img/about/fanfare-1966.jpg">
            <figcaption class="figure-caption">Fanfare Alem in het oprichtingsjaar 1966.</figcaption>
          </figure>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 text-justify align-middle">
        <p class="about-paragraph">Het bestuur van het jeugdwerk “De Jeugdcentrale” fungeerde als eerste bestuur van
          “Jeugdfanfare Alem” met aan
          het hoofd Toon Holtappels, thans erevoorzitter van onze vereniging. Eerste dirigent was wijlen Jan van
          Neerijnen, destijds leraar aan de muziekschool en later een zeer bekende componist in de muziekwereld.
        </p>

        <p class="about-paragraph">
          Het algemene oordeel destijds was dat de Alemse muziekvereniging geen lang leven beschoren zou zijn. Alem had
          toen niet meer dan 500 inwoners, het ledenaantal was 28, er waren géén uniformen en er werd gespeeld op
          geleende instrumenten.
        </p>

        <p class="about-paragraph">
          Het tegendeel is echter bewezen. “Jeugdfanfare Alem” is uitgegroeid tot Fanfare Alem. Kinderen van toenmalige
          jeugdleden zijn inmiddels ook lid geworden en zo is de vereniging gestaag gegroeid naar een ledenaantal van 55
          in de leeftijd van 10 tot 70 jaar. 13 leden maken deel uit van de Slagwerkgroep. Het Fanfare-orkest komt uit
          in 5e divisie, de Slagwerkgroep komt uit in de 4e divisie maar speelt voornamelijk stukken van de 2e en 3e
          divisie.
        </p>
      </div>
      <div class="col-md-8 align-middle justify-content-center">
        <div class="view overlay">
          <figure>
            <img class="img-fluid" src="../../assets/img/about/fanfare-erevoorzitters.jpg">
            <figcaption class="figure-caption">Erevoorzitters Peter van Boxtel & wijlen Toon Holtappels.</figcaption>
          </figure>
        </div>
      </div>
    </div>
  </div>
</section>
