<div class="separator z-depth-2 black-top-border black-bottom-border"></div>
<!-- Section: Contact v.2 -->
<section class="px-3 px-lg-5 pb-2 pb-lg-5 pt-2 pt-lg-3">

  <!-- Section heading -->
  <h2 class="h1-responsive font-weight-bold text-center my-4">Sponsors</h2>
  <div class="d-flex justify-content-around justify-content-center align-items-between flex-wrap">
    <ng-container *ngFor="let sponsor of sponsors">
      <a *ngIf="sponsor.url" href="{{sponsor.url}}" target="_blank">
        <img class="sponsor-image m-2" src="../../assets/img/sponsors/{{sponsor.image}}">
      </a>
      <img *ngIf="!sponsor.url" class="sponsor-image m-2" src="../../assets/img/sponsors/{{sponsor.image}}">
    </ng-container>
  </div>
</section>
