<!-- Footer -->
<footer class="page-footer font-small red-background">

  <!-- Footer Elements -->
  <div class="container">
    <!-- Grid row-->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 pt-2 pb-2">
        <div class="mb-5 flex-center">

          <!-- Facebook -->
          <a class="fb-ic" href="https://www.facebook.com/fanfarealem" target="_blank">
            <mdb-icon fab icon="facebook" size="3x" class="white-text mx-3"></mdb-icon>
          </a>
          <!-- Twitter -->
          <a class="tw-ic" href="mailto:info@fanfare-alem.nl">
            <mdb-icon far icon="envelope" size="3x" class="white-text mx-3"></mdb-icon>
          </a>
          <!-- Google +-->
          <a class="gplus-ic" href="tel:0631288309">
            <mdb-icon fas icon="phone" size="3x" class="white-text mx-3"></mdb-icon>
          </a>
        </div>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row-->

  </div>
  <!-- Footer Elements -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© {{ year }} Copyright
    <a href="https://www.linkedin.com/in/robvanboxtel0597/" target="_blank"> Rob van Boxtel</a>
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->
