import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FacebookPost, FacebookFeed } from 'src/app/news/models/facebook-post';

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  private baseUrl = 'https://graph.facebook.com/v16.0/298381580346316/posts?access_token=EAAhWtYtuOAoBAMiK1AtBrBLeKJjwMSewlm3d5AYX1XcbX4gDNFz6IWo7Q2CSbIc44lCVA6zejZApl5u6xbnWcsZAKb3CW4F3OJAZC5MRFZCSi7ZAp6aM8TP54azmrlNYl9I2RZBylZBZAmiKs106WyNLOgDZCTplRa3ftv3oTCI77E3qRRhFocotJZCTU40Ey1ysUGSHPgTFVwc3u3VXYdxUNd&debug=all&fields=title%2Cmessage%2Cfull_picture%2Ccreated_time%2Cpermalink_url&format=json&limit=3&method=get&pretty=0&suppress_http_code=1&transport=cors'

  constructor(
    private http: HttpClient
  ) {
  }

  getFeed(): Observable<FacebookPost[]> {
    return this.http.get<FacebookFeed>(this.baseUrl).pipe(map(r => r.data));
  }
}
