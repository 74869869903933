import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  ageInYears: number;

  constructor() {
    this.ageInYears = this.calculateAgeInYears();
  }

  ngOnInit(): void {}

  calculateAgeInYears(): number {
    let date = Date.UTC(1965, 10, 10);
    let ageDifMs = Date.now() - date;
    let ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}
