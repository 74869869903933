<div class="separator z-depth-2 black-top-border black-bottom-border" id="photos"></div>
<!-- Section: Contact v.2 -->
<section class="px-3 px-lg-5 pb-2 pb-lg-5 pt-2 pt-lg-3">

  <!-- Section heading -->
  <h2 class="h1-responsive font-weight-bold text-center my-4">Foto's</h2>

  
  <img class="wip" src="../../assets/img/wip.png">

  <!-- <div class="card" style="width: 18rem;">
    <img
      src="https://mdbootstrap.com/img/new/standard/nature/182.jpg"
      class="card-img-top"
      alt="..."
    />
    <div class="card-body">
      <p class="card-text">
        Some quick example text to build on the card title and make up the bulk of the
        card's content.
      </p>
    </div>
  </div> -->

</section>
