import { Component, OnInit } from '@angular/core';
import { FacebookService } from 'src/services/facebook.service';
import { FacebookPost } from 'src/app/news/models/facebook-post';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  loading: boolean = true;
  posts: FacebookPost[];

  constructor(
    private service: FacebookService
  ) {
    service.getFeed().subscribe(data => {
      this.posts = data;
      this.loading = false;
    });
  }

  openInNewTab(link: string) {
    let win = window.open(link, '_blank');
    win.focus();
  }

  ngOnInit(): void {
  }

}
