import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  basePath = '../../assets/img/header/';
  photos = [`${this.basePath}foto5.jpg`, `${this.basePath}foto3.jpg`, `${this.basePath}foto4.jpg`, `${this.basePath}foto1.jpg`];

  constructor() {
  }

  ngOnInit(): void {}
}
