import { Component, OnInit, ViewChild } from '@angular/core';
import { NavbarComponent as navmdb } from '../../../node_modules/ng-uikit-pro-standard/lib/free/navbars/navbar.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

  @ViewChild('navbarid', {static: false}) navbaridRef: navmdb;

  navItems: NavItem[] = [
    { name: 'Home', link: 'news'},
    { name: 'Over', link: 'about'},
    { name: 'Agenda', link: 'agenda'},
    { name: 'Contact', link: 'contact'},
    { name: 'Foto\'s', link: 'photos'},
  ]

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    if (window.window.innerWidth < 992)
      setTimeout(() => {
        this.navbaridRef.toggle(); //Hide the collapse menu after click
      }, 1300);
   }
}

export class NavItem {
  name: string;
  link: string;
}
