<section class="px-3 px-lg-5 pb-2 pb-lg-5 pt-2 pt-lg-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8 align-middle justify-content-center">
          <div class="view overlay">
            <figure>
              <img class="img-fluid" src="../../assets/img/about/fanfare-koningsdag.jpg">
              <figcaption class="figure-caption">Fanfare Alem marcherend tijdens Koningsdag.</figcaption>
            </figure>
          </div>
        </div>
        <div class="col-md-4 text-justify align-middle">
          <p class="h1-responsive">Activiteiten</p>
          <p class="about-paragraph">
            Fanfare Alem is een vrolijke en frisse muziekvereniging wat tot uiting komt in de muziekkeuze en haar in het
            oog springende rode uniform. Gezelligheid staat voorop zonder daarbij de prestatie uit het oog te verliezen.
            Een goede prestatie stemt immers iedereen tot tevredenheid. De muziekkeuze kenmerkt zich als populair en
            herkenbaar voor zowel muzikant als publiek.
          </p>
          <p class="about-paragraph">
            Bij activiteiten als Koningsdag, Dodenherdenking (1x per drie jaar) en de intocht van Sinterklaas is Fanfare
            Alem present.
          </p>
          <p class="about-paragraph">
            Jaarlijks terugkerende activiteiten zijn het nieuwjaarsconcert, de leerlingenavond en de donateuractie in
            Alem.
          </p>
          <p class="about-paragraph">
            Fanfare Alem geeft regelmatig een uitwisselingsconcert met andere muziekverenigingen, is present bij serenades
            t.g.v. jubilea en heeft enkele keren deelgenomen aan concoursen. De laatste keer was tijdens het KNFM-concours
            in Wijchen in november 2013. Een uitstekende prestatie werd beloont met een 1e prijs.
          </p>
        </div>
  
      </div>
      <div class="row">
        <div class="col-md-8 align-middle justify-content-center">
          <div class="view overlay">
            <figure>
              <img class="img-fluid" src="../../assets/img/about/fanfare-lenk-1970.jpg">
              <figcaption class="figure-caption">Fanfare Alem gedurende het eerste bezoek aan Lenk in 1970.</figcaption>
            </figure>
          </div>
        </div>
        <div class="col-md-4 text-justify align-middle">
          <p class="about-paragraph">
            In het verleden organiseerde Fanfare Alem regelmatig (1x per twee à drie jaar) een
            muziekweekend in Alem. Sinds 1967 hebben hieraan reeds meer dan 12.000 muzikanten uit binnen- en buitenland
            deelgenomen.
          </p>
  
          <p class="about-paragraph">
            Fanfare Alem was ook oprichter van het inmiddels ter ziele gegane tweejaarlijkse festival Muziekcup
            Bommelerwaard. De laatste jaren heeft Fanfare Alem helaas niet meer meegedaan omdat het competitie-element
            veel te veel de overhand kreeg. Onze leden konden zich hier niet meer met verenigen omdat de festivalgedachte
            was inmiddels ver naar de achtergrond was verdreven.
          </p>
  
          <p class="about-paragraph">
            Fanfare Alem heeft sinds 1970 ook een muzikale uitwisseling met Musikgesellschaft Lenk uit Lenk (Berner
            Oberland) in Zwitserland. In 2019, tijdens Koningsdag, zijn onze Zwitserse vrienden
            voor het laatst te gast geweest in Alem, in oktober 2016 heeft Fanfare Alem voor het laatst een bezoek gebracht aan Lenk.
            Een nieuw uitwisselingsbezoek was voorzien in 2020, maar deze moest vanwege de coronapandemie uitgesteld worden tot 2021.
          </p>
        </div>
      </div>
    </div>
  </section>