<div class="separator z-depth-2 white-top-border black-bottom-border" id="news"></div>
<section class="px-3 px-lg-5 pb-2 pb-lg-5 pt-2 pt-lg-3">
  <div class="text-center">
    <h2 class="h1-responsive font-weight-bold text-center my-4">Nieuws</h2>
    <p class="h4-responsive text-muted">Hieronder vindt u het laatste nieuws, direct vanaf onze Facebook-pagina.</p>
    <div class="fb-page" data-href="https://www.facebook.com/fanfarealem/" data-tabs="" data-width="" data-height=""
      data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
      <blockquote cite="https://www.facebook.com/fanfarealem/" class="fb-xfbml-parse-ignore"><a
          href="https://www.facebook.com/fanfarealem/">Fanfare Alem</a></blockquote>
    </div>
    <hr>
  </div>

  <div class="d-flex justify-content-center" *ngIf="loading">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  
  <div class="card-deck">
    <ng-container *ngFor="let post of posts">
      <!-- Card -->
      <mdb-card class="hoverable">
        <div class="view overlay waves-light" mdbWavesEffect>
          <!-- Card img -->
          <mdb-card-img class="fb-card-image"
            [src]="post.full_picture ? post.full_picture : '../assets/img/header_logo.png'" alt="Card image cap">
          </mdb-card-img>
          <a>
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
        <!--Card content-->
        <mdb-card-body>
          <!--Title-->
          <mdb-card-title>
            <h4>{{ post.created_time | date:'fullDate' }}</h4>
          </mdb-card-title>

          <!--Text-->
          <mdb-card-text class="text-justify">{{post.message}}
          </mdb-card-text>

          <button type="button" (click)="openInNewTab(post.permalink_url)" class="btn btn-fb">Lees verder</button>
        </mdb-card-body>
      </mdb-card>
    </ng-container>
  </div>
</section>
