import { Component, Input } from '@angular/core';
import { ProfileDescription } from 'src/app/about/models/profile-description';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  @Input() profile: ProfileDescription;

  defaultImage: string = '../../../../assets/img/about/blank-profile.png';
}
