<!-- <div class="d-flex container-fluid text-white bg-dark" id="header-title">
  <h2 class="h2-responsive">Welkom bij&nbsp;</h2>
  <h1 class="h1-responsive"><b>Fanfare Alem</b></h1>
</div> -->
<app-navbar></app-navbar>
<mdb-carousel class="carousel slide carousel-fade" [isControls]="false" [animation]="'fade'">
  <img src="../../assets/img/header_logo.png" id="header-logo">
  <ng-container *ngFor="let photo of photos">
    <mdb-carousel-item class="justify-content-center">
      <div class="view w-100">
        <img class="d-block w-100 header-image" src="{{photo}}" alt="Fanfare Alem Header">
        <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
      </div>
    </mdb-carousel-item>
  </ng-container>
    
</mdb-carousel>