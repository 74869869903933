import { Component, OnInit } from '@angular/core';
import { ProfileDescription } from 'src/app/about/models/profile-description';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {

  board: ProfileDescription[] = [
    { name: "Hans Hanegraaf", function: "Voorzitter", image: '../../../assets/img/about/board/Hans.jpg' },
    { name: "Anne van Boxtel", function: "Secretaris", image: '../../../assets/img/about/board/Anne.jpg' },
    { name: "Corrie de Leeuw", function: "Bestuurslid", image: '../../../assets/img/about/board/Corrie.jpg' },
    { name: "Daan van Dijk", function: "Penningmeester", image: '../../../assets/img/about/board/Daan.jpg' },
    { name: "Marc van Oers", function: "Bestuurslid", image: '../../../assets/img/about/board/Marc.jpg' },
    { name: "Marjolein van Boxtel", function: "Bestuurslid", image: '../../../assets/img/about/board/Marjolein.jpg' }
  ];

  conductors: ProfileDescription[] = [
    { name: "Ton Peters", function: "Dirigent Fanfare Alem", image: '../../../assets/img/about/tonpeters.jpg' },
    { name: "Lynyrd Denissen", function: "Instructeur Slagwerkgroep Alem \nDocent slagwerk", image: '../../../assets/img/about/lynyrddenissen.jpg' }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
