<!--Navbar-->
<mdb-navbar #navbarid SideClass="navbar navbar-expand-lg navbar-dark z-depth-3">
    <!-- Collapsible content -->
    <links>
        <!-- Links -->
        <ul class="navbar-nav mr-auto">
            <li class="nav-item">
                <a class="nav-link waves-light" mdbWavesEffect mdbPageScroll (click)="onClick()" href="#news">Home</a>
            </li>
            <li class="nav-item">
                <a class="nav-link waves-light" mdbWavesEffect mdbPageScroll (click)="onClick()" href="#about">Over</a>
            </li>
            <li class="nav-item">
                <a class="nav-link waves-light" mdbWavesEffect mdbPageScroll (click)="onClick()" href="#organization">Organisatie</a>
            </li>
            <!-- <li class="nav-item">
                <a class="nav-link waves-light" mdbWavesEffect mdbPageScroll (click)="onClick()" href="#agenda">Agenda</a>
            </li> -->
            <li class="nav-item">
                <a class="nav-link waves-light" mdbWavesEffect mdbPageScroll (click)="onClick()" href="#contact">Contact</a>
            </li>
            <!-- <li class="nav-item">
                <a class="nav-link waves-light" mdbWavesEffect mdbPageScroll (click)="onClick()" href="#photos">Foto's</a>
            </li> -->
        </ul>
        <!-- Links -->
    </links>
    <!-- Collapsible content -->

</mdb-navbar>
<!--/.Navbar-->