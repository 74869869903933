<div class="separator z-depth-2 black-top-border black-bottom-border" id="organization"></div>
<section class="px-3 px-lg-5 pb-2 pb-lg-5 pt-2 pt-lg-3">

  <h2 class="h1-responsive font-weight-bold text-center my-4">Organisatie</h2>

  <p class="h1-responsive text-center">Bestuur</p>
  <p class="grey-text w-responsive mx-auto mb-5 text-center">Het bestuur van Fanfare Alem bestaat momenteel uit de
    volgende mensen.</p>

  <div class="row justify-content-center">
    <ng-container *ngFor="let member of board">
      <div class="col-lg col-md-3">
        <profile [profile]="member"></profile>
      </div>
    </ng-container>
  </div>
</section>

<!-- Muzikale leiding -->
<section class="px-3 px-lg-5 pb-2 pb-lg-5 pt-2 pt-lg-3 bg-dark white-text">

  <p class="h1-responsive text-center">Muzikale leiding</p>

  <div class="row justify-content-center">
    <ng-container *ngFor="let member of conductors">
      <div class="col-md col-md-3">
        <profile [profile]="member"></profile>
      </div>
    </ng-container>
  </div>

</section>