import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import localeNl from '@angular/common/locales/nl'
import localeNlExtra from '@angular/common/locales/extra/nl'

import { MDBBootstrapModulesPro, MDBSpinningPreloader } from 'ng-uikit-pro-standard'
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NewsComponent } from './news/news.component';
import { HttpClientModule } from '@angular/common/http';
import { FacebookService } from 'src/services/facebook.service';
import { registerLocaleData } from '@angular/common';
import { AboutComponent } from './about/about.component';
import { FooterComponent } from './footer/footer.component';
import { OrganizationComponent } from './about/organization/organization.component';
import { ActivitiesComponent } from './about/activities/activities.component';
import { HistoryComponent } from './about/history/history.component';
import { AgendaComponent } from './agenda/agenda.component';
import { ContactComponent } from './contact/contact.component';
import { PhotosComponent } from './photos/photos.component';
import { ProfileComponent } from './about/organization/profile/profile.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SponsorsComponent } from './sponsors/sponsors.component'

registerLocaleData(localeNl, 'nl', localeNlExtra);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavbarComponent,
    NewsComponent,
    AboutComponent,
    FooterComponent,
    OrganizationComponent,
    ActivitiesComponent,
    HistoryComponent,
    AgendaComponent,
    ContactComponent,
    PhotosComponent,
    ProfileComponent,
    SponsorsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModulesPro.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    MatSnackBarModule
  ],
  providers: [
    MDBSpinningPreloader,
    FacebookService,
    { provide: LOCALE_ID, useValue: 'nl' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
