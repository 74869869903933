<div class="separator z-depth-2 black-top-border black-bottom-border" id="contact"></div>
<!-- Section: Contact v.2 -->
<section class="px-3 px-lg-5 pb-2 pb-lg-5 pt-2 pt-lg-3">

  <!-- Section heading -->
  <h2 class="h1-responsive font-weight-bold text-center my-4">Contact</h2>

  <!-- Grid row -->
  <div class="row justify-content-center">

    <!-- Grid column -->
    <div class="col-md-3 text-center">
      <ul class="list-unstyled mb-0">
        <li>
          <mdb-icon fas icon="map-marked" size="2x" class="black-text"></mdb-icon>
          <p>
            Fanfare Alem<br>
            p/a 't Buske 11<br>
            5335 LG &nbsp;&nbsp; Alem
          </p>
        </li>
        <li>
          <mdb-icon fas icon="phone" size="2x" class="mt-0 mt-lg-4 black-text"></mdb-icon>
          <p>06 3128 8309</p>
        </li>
        <li>
          <mdb-icon fas icon="envelope" size="2x" class="mt-0 mt-lg-4 black-text"></mdb-icon>
          <p class="mb-0">info@fanfare-alem.nl</p>
        </li>
      </ul>
    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row -->

</section>
<!-- Section: Contact v.2 -->
